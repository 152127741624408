@use "mixins";
@import "colors";

.contact-teaser {
  background-position: center;
  background-repeat: no-repeat;

  &.big {
    background: $blue;
    background-image: url("../assets/images/world-bg.svg");
    padding: 120px 0;

    img {
      width: 250px;
      height: 250px;
    }

    .flex {
      justify-content: center;

      @include mixins.tablet {
        text-align: center;
        align-items: center;
        padding: 0 24px;
        margin: 0;
      }
    }

    p {
      margin-bottom: 60px;
    }
  }

  &.job {
    background-color: $blue-grey;
  }

  &.small {
    background: $blue-grey;
    background-image: url("../assets/images/pattern.svg");
    padding: 80px 0;

    img {
      width: 168px;
      height: 168px;
    }

    p {
      margin-bottom: 37px;
    }

    .flex {
      margin-left: 100px;

      @include mixins.tablet {
        text-align: center;
        align-items: center;
        padding: 0 24px;
        margin: 0;
      }
    }
  }

  .flex {
    gap: 100px;

    @include mixins.tablet {
      flex-direction: column;
    }
  }

  p {
    font-weight: 700;
  }
}
