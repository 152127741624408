@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;800&display=swap');

a {
  text-decoration: none;
  color: inherit;
  transition: all 200ms ease;

  &:active,
  &:hover,
  &:focus {
    color: $white;
    text-shadow: 0 0 0 $white;
  }
}

a.active {
  text-shadow: 0 0 0 $white;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .nav-link {
      transition: unset;
    }
  }
}

nav a.active {
  border-bottom: 4px solid $white;

  @include mixins.desktop {
    padding-bottom: 22px;
  }
  @include mixins.big-desktop {
    padding-bottom: 27px;
  }
}

.App {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

main {
  padding-bottom: 120px;
}

body,
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

body {
  background: #31393f;
  color: $white;
  overflow-x: hidden;
}

.bg-img {
  position: absolute;
  top: 0;
  z-index: -10;
}

.blue-text {
  background-color: $blue;
  padding: 105px 0;
}

.btn {
  transition: transform 250ms ease;
  text-align: center;
  display: inline-block;
  font-size: 1rem;
  color: $black;
  background: $white;
  border: 0;
  padding: 18px 38px;
  line-height: 1;

  &:active,
  &:hover,
  &:focus {
    transform: scale(1.05);
    color: $blue;
    font-weight: 400;
  }

  &-light{
	background: rgba($white, 0.5);
  }
}

.btn-small {
  padding: 12px 46px;
}

h1 {
  font-weight: 800;
  font-size: 3rem;
  line-height: 1.4;
}

h2 {
  overflow-wrap: break-word;
  font-weight: 800;
  font-size: 2rem;
  line-height: 1.4;
}

h3 {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.7;
}

img {
  object-fit: cover;
  object-position: center;
}

.line {
  flex-shrink: 0;
  width: 3px;
  background: $white;

  @include mixins.mobile {
    width: 100%;
    height: 3px;
  }
}

p,
li {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
}

p {
  white-space: pre-line;
  hyphens: auto;
}

.justify {
  text-align: justify;
}

.job-page {
  h1 {
    margin-top: 30px;
    font-size: 2.5rem;
    @include mixins.mobile {
      font-size: 2rem;
      word-break: break-all;
    }
  }
  white-space: pre-line;
  ul {
    white-space: normal;
    list-style-type: disc;
    padding-left: 20px;
  }
  section {
    margin-bottom: 150px;

    @include mixins.mobile {
      margin-bottom: 100px;
    }
  }
}

.mood-img {
  @include mixins.mobile {
    height: 340px;
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: $blue !important;
  width: 60px !important;
  height: 60px !important;
  background: rgba($white, 0.6);
  border-radius: 50%;
}

.real-list {
  list-style-type: disc;
  padding-left: 20px;
}

ul {
  list-style-type: none;
}

.privacy,
.imprint {
  margin-top: 150px;

  ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 24px;
  }
  h3 {
    margin-bottom: 18px;
  }
  h4 {
    margin-bottom: 10px;
  }
}

section {
  margin-bottom: 200px;

  @include mixins.mobile {
    margin-bottom: 150px;
  }
}

.text-link {
  text-decoration: underline;
  text-underline-offset: 1px;

  &:active,
  &:hover,
  &:focus {
    color: $white;
    font-weight: 400;
    text-decoration: none;
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.wrapped {
  @include mixins.desktop {
    margin-left: 150px;
    margin-right: 150px;
  }

  @include mixins.big-desktop {
    margin-left: 375px;
    margin-right: 375px;
  }

  @include mixins.qhd-desktop {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
  }

  @include mixins.tablet {
    margin-left: 40px;
    margin-right: 40px;
  }

  @include mixins.mobile {
    margin-left: 24px;
    margin-right: 24px;
  }
}
