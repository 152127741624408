@use "mixins";
@import "colors";

.job-teaser {
  background: $blue;
  background-image: url("../assets/images/world-bg.svg");
  padding: 120px 0;

  .swiper {
    @include mixins.desktop {
      padding: 0 80px !important;
    }

    @include mixins.big-desktop {
      padding: 0 150px !important;
    }
	.swiper-slide{
		height: unset;
	}

    .swiper-slide:nth-child(even) {
      .job-card {
        background: $blue;
      }
    }
  }

  .job-card {
    display: flex;
    flex-direction: column;
    align-items: center;
	justify-content: space-between;
    gap: 50px;
    width: 350px;
	height: 100%;
    padding: 40px;
    background: $blue-grey;
    border-bottom: 4px solid $white;
  }
}
