@use "mixins";
@import "colors";

.slider-large {
  .swiper {
    max-width: 1170px;

    @include mixins.big-desktop {
      max-width: 1400px;
    }

    @include mixins.tablet {
      width: 100%;
    }

    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    min-width: fit-content !important;
  }

  .slider-inner {
    width: 650px;
    height: 1px;
    min-height: 603px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;

    h2 {
      font-size: 1.8rem;
    }

    @include mixins.tablet {
      width: 100%;
    }

    &:hover,
    &:focus {
      .slider-text {
        transform: translateY(0px);
      }

      p {
        opacity: 1;
      }
    }

    .slider-text {
      transition: transform 250ms ease;
      padding: 48px;
      height: 100%;
      background: rgba($black, 0.8);
      transform: translateY(450px);

      @include mixins.mobile {
        padding: 24px;
        overflow-y: scroll;
      }
    }

    p {
      transition: opacity 250ms ease;
      opacity: 0;
    }
  }
}
