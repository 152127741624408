@use "mixins";
@import "colors";

.world-map {
  img {
    margin-top: 200px;
    margin-bottom: 80px;
    width: 100%;

    @include mixins.mobile {
      margin-top: 100px;
    }
  }

  p.bold {
    font-size: 1.4rem;
    font-weight: 700;
  }
}
