@use "mixins";
@import "colors";

footer {
  background: $blue;
  width: 100%;
  max-height: 950px;

  .content {
    padding: 100px 0;
    display: flex;
    gap: 30px;

    @include mixins.tablet {
      flex-direction: column;
    }

    h3 {
      margin-bottom: 32px;
      font-size: 1.2rem;
    }

    div {
      width: 33%;

      @include mixins.tablet {
        width: 100%;
      }
    }

    .links {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
    }

    img {
      width: 100%;
    }

    svg {
      margin-right: 16px;
    }
  }

  .bottom-line {
    background: #00122f;
    padding: 28px 0;
  }
}
