nav {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 60px;
  transition: all 300ms ease-in-out;
  line-height: 0;
  font-size: 1rem;
  background: $blue;
  overflow: hidden;
  z-index: 100;

  @include mixins.tablet {
    position: fixed;
    width: 100%;
    background: transparent;
    padding: 10px 40px;
    flex-wrap: wrap;
    line-height: 1;
    height: 72px;

    &.toggled {
      height: 645px;
      background: $white;
      color: $blue;
    }
  }

  .logo {
    display: flex;
    align-items: center;
    width: 250px;
    background: white;
    padding: 10px 0;
    padding-left: 40px;
    margin-top: -20px;
    margin-bottom: -20px;
    margin-left: -60px;
    height: 70px;
    -webkit-clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);

    @include mixins.big-desktop {
      width: 300px;
      padding-left: 60px;
      height: 80px;
    }

    @include mixins.tablet {
      background: transparent;
      padding: 0;
      margin: 0;
      height: 50px;
      flex-basis: 90%;
    }

    img {
      height: 100%;
    }

    a {
      width: 100%;
      height: 100%;
    }
  }

  ul {
    display: flex;
    align-items: center;

    @include mixins.desktop {
      justify-content: flex-end;
      width: 100%;
      gap: 3%;
    }

    @include mixins.big-desktop {
      gap: 60px;
    }

    @include mixins.tablet {
      align-items: flex-start;
      position: relative;
      flex-direction: column;
      gap: 40px;
      margin-top: 30px;

      li {
        font-size: 1.2rem;
      }
    }
  }

  &.scrolled {
    -webkit-box-shadow: 0px 8px 11px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 8px 11px 1px rgba(0, 0, 0, 0.2);

    @include mixins.tablet {
      background: $white;
    }

    .toggler {
      background: $blue;

      &::before,
      &::after {
        background: $blue;
      }
    }
  }

  .toggler {
    transition: all 250ms ease;
    position: relative;
    width: 22px;
    height: 3px;
    background: $white;
    border-radius: 2px;

    &.toggled {
      background: transparent;

      &::before {
        background: $blue;
        bottom: 0px;
        transform: translate3d(0, 0, 0) rotate(45deg);
      }

      &::after {
        background: $blue;
        top: 0px;
        transform: translate3d(0, 0, 0) rotate(-45deg);
      }
    }

    &::before,
    &::after {
      transition: all 250ms ease;
      content: "";
      position: absolute;
      width: 28px;
      height: 3px;
      background: $white;
      right: 0px;
      border-radius: 2px;
      transform: translate3d(0, 0, 0) rotate(0deg);
    }

    &::before {
      bottom: 8px;
    }

    &::after {
      top: 8px;
    }
  }
}
