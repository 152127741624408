.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  .btn {
    color: $white;
    background: $blue;

    &:hover,
    &:focus {
      color: $white;
    }
  }

  input,
  textarea {
    background: $white;
    border: 0;
    padding: 16px 24px;
    font-size: 1.2rem;
    color: $blue;

    &:active,
    &:focus {
      outline: 3px solid $blue;
    }
  }

  textarea {
    resize: none;
    min-height: 300px;
  }
}

.switch-button {
  background: $white;
  border-radius: 30px;
  border: 2px solid $blue;
  overflow: hidden;
  width: 64px;
  text-align: center;
  letter-spacing: 1px;
  color: $blue;
  position: relative;
  padding-right: 50px;
  position: relative;

  &:before {
    content: "de";
    position: absolute;
    top: 0;
    bottom: 2px;
    right: 0;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
    @include mixins.tablet {
      bottom: 1px;
    }
  }

  &-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &:checked + .switch-button-label:before {
      transform: translateX(33px);
      transition: transform 300ms linear;
      @include mixins.tablet {
        transform: translateX(30px);
      }
    }

    & + .switch-button-label {
      position: relative;
      padding: 0px 0;
      padding-left: 5px;
      transform: translateY(-1px);
      display: block;
      user-select: none;
      pointer-events: none;

      &:before {
        content: "";
        background: $blue;
        height: 25px;
        width: 25px;
        position: absolute;
        left: 0;
        top: 1px;
        border-radius: 50%;
        transform: translateX(0);
        transition: transform 300ms;
        border: 1px solid $white;

        @include mixins.tablet {
          top: 2.5px;
          height: 28px;
          width: 28px;
        }
      }

      .switch-button-label-span {
        position: relative;
      }
    }
  }
}
