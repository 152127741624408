.align-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.flex {
  display: flex;
}

.hide-desktop {
  @include mixins.desktop {
    display: none;
  }
}

.justify-center {
  justify-content: center;
}

.mb-18 {
  margin-bottom: 18px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-54 {
  margin-bottom: 54px;
}

.mb-84 {
  margin-bottom: 84px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-200 {
  margin-top: 200px;
}

.py-100 {
  padding: 0 100px;

  @include mixins.mobile {
    padding: 0 20px;
  }
}

.text-blue {
  color: $blue;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.w-mb-100 {
  @include mixins.mobile {
    width: 100%;
  }
}
