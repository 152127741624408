@use "mixins";
@import "colors";

.charts {
  .charts-wrapper {
    width: 100%;
    display: flex;
    gap: 70px;
    justify-content: center;

    @include mixins.tablet {
      flex-direction: column;
      gap: 80px;
    }
  }

  .chart {
    height: fit-content;
  }

  .chart-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .chart-wrapper {
      position: relative;
      max-height: 300px;

      canvas {
      max-height: 300px !important;
      max-width: 300px !important;
      }
    }

    .chart-label {
      position: absolute;
      font-size: 3.4rem;
      font-weight: 700;

      sup {
        font-size: 2.5rem;
      }
      sub {
        font-size: 1.5rem;
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -20%);
        width: 100%;
        text-align: center;
      }
    }
  }

  .text {
    margin-top: 30px;
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 1.3;
    max-width: 300px;
    
    hyphens:          none;
    -webkit-hyphens:  none;
    -moz-hyphens:     none;

    @include mixins.tablet {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
