.home {
  .full-width-teaser {
    .bg-img {
      @include mixins.qhd-desktop {
        width: 60%;
      }
    }
  }
}

.home-text {
  gap: 50px;

  @include mixins.mobile {
    gap: 32px;
    flex-direction: column;

    h2,
    h3 {
      text-align: left;
    }
  }
}

.logo-teaser {
  background: $white-2;
  padding: 70px 100px;
  align-items: center;
  position: relative;

  @include mixins.tablet {
  padding: 24px;

  }

  .logo-box {
    object-fit: contain;
    max-height: 120px;
  }

  p {
    color: $blue-grey;
    font-size: 12px;
    position: absolute;
    bottom: 20px;
    left: 100px;

    @include mixins.tablet {
      top: 100px;
      left: 30%;
     }

    @include mixins.mobile {
      top: 90px;
      left: 20px;
     }
  }
}

.service-squares {
  display: flex;
  gap: 30px;
  color: $blue;

  @include mixins.mobile {
    flex-direction: column;
  }

  .service-card {
    position: relative;
    width: 33%;
    height: 250px;
    background: $white;
    border-bottom: 8px solid $blue-grey;
    box-shadow: 0px 19px 33px rgba(111, 109, 113, 0.13);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 20px;
    
    p {
      hyphens:          none;
      -webkit-hyphens:  none;
      -moz-hyphens:     none;
    }

    @include mixins.mobile {
      width: 100%;
      flex-shrink: 0;
      height: 180px;
    }

    &:active,
    &:hover,
    &:focus {
      transform: scale(1.05);
      color: $blue;
      font-weight: 400;
    }
  }

  img {
    position: absolute;
    top: 6.25%;
    left: 6.25%;
  }

  p {
    font-size: 1.4rem;
  }
}
