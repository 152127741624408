$mobile-width: 576px;
$tablet-width: 996px;
$desktop-width: 997px;
$big-desktop-width: 1440px;
$QHD-desktop-width: 2100px;

@mixin mobile {
  @media (max-width: $mobile-width) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tablet-width) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}

@mixin big-desktop {
  @media (min-width: $big-desktop-width) {
    @content;
  }
}
@mixin qhd-desktop {
  @media (min-width: $QHD-desktop-width) {
    @content;
  }
}
