@use "mixins";
@import "colors";

.full-width-teaser {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }

  .bg-img {
    top: 60px;
    right: 0;
    height: 100%;

    @include mixins.mobile {
      top: 50px;
    }
  }

  &.big {
    height: 1400px;

    @include mixins.mobile {
      height: 1200px;
    }

    .bg-img {
      height: 80%;

      @include mixins.mobile {
        top: 100px;
      }
    }
  }

  .border-bottom {
    margin-top: 70px;
    width: 100%;
    height: 40px;
    background: $blue-grey;

    @include mixins.mobile {
      margin-top: 40px;
      height: 15px;
    }
  }

  .overlay {
    position: absolute;
    background: $blue;
    width: 100%;
    top: 0;
    bottom: 0;
    z-index: -1;
    clip-path: polygon(0 0, 50% 0, 90% 100%, 0% 100%);
  }

  .text {
    margin-top: 130px;

    @include mixins.desktop {
      margin-left: 150px;
      width: 45%;
    }
  
    @include mixins.big-desktop {
      margin-left: 375px;
      width: 30%;
    }
  
    @include mixins.qhd-desktop {
      margin-left: 800px;
      width: 25%;
    }
  
    @include mixins.tablet {
      margin-left: 40px;
      width: 60%;
    }
  
    @include mixins.mobile {
      margin: 0;
      margin-top: 100px;
      padding: 0 24px;
      width: 100%;
    }

    // @include mixins.desktop {
    //   width: 45%;
    // }

    // @include mixins.big-desktop {
    //   width: 35%;
    // }
  }
}

.grid-cards {
  margin-top: 61px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  .card {
    height: 200px;
    background: $white;
    border-bottom: 8px solid $blue-grey;
    color: $blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 25px;
    p {
      font-weight: 800;
      line-height: 1.2;
    }
  }
}
